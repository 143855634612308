<main style="display:block;height:auto">
  <app-page-header [logoUrl]="logoUrl()"></app-page-header>
  <router-outlet></router-outlet>
  <app-page-footer></app-page-footer>
  @if (alertService.alertOpen()) {
    <alert [type]="alertService.alertType()" [dismissOnTimeout]="alertService.alertTimeout()" (onClosed)="alertService.alertOpen.set(false)">
      {{alertService.alertMsg()}}
    </alert>
  }
</main>
