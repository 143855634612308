import { Component, inject, input, OnInit } from '@angular/core';
import { NavBarComponent } from '../nav-bar/nav-bar.component';
import { ClientSessionStateService } from '../../services/sessionState.service';

@Component({
  selector: "app-page-header",
  standalone: true,
  imports: [NavBarComponent],
  templateUrl: "./page-header.component.html",
  styleUrl: "./page-header.component.scss",
})
export class PageHeaderComponent  {

  logoUrl = input("");
}
