import { Component, ElementRef, Signal, ViewChild } from "@angular/core";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { filter, Subscription } from "rxjs";
import { ClientSessionStateModel } from "../../models/sessionState.model";
import { OnDestroy, OnInit } from "@angular/core";
import { ClientSessionStateService } from "../../services/sessionState.service";

@Component({
  selector: "app-nav-bar",
  standalone: true,
  imports: [RouterModule],
  templateUrl: "./nav-bar.component.html",
  styleUrl: "./nav-bar.component.scss",
})
export class NavBarComponent implements OnInit, OnDestroy {
  state!: Signal<ClientSessionStateModel>;
  subscriptions: Subscription = new Subscription();
  @ViewChild("back", { static: false }) back!: ElementRef;

  step0 = false;
  step1 = false;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = false;
  step6 = false;

  constructor(private router: Router, private stateSvc: ClientSessionStateService) {}

  ngOnInit(): void {
    this.state = this.stateSvc.getState();

    this.subscriptions.add(
      this.router.events
        .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.resetSteps();
          if (event.url.includes("continue-project") || event.url.includes("start")) {
            this.step1 = true;
          } else if (event.url.includes("box-style")) {
            this.step2 = true;
          } else if (event.url.includes("box-options")) {
            this.step3 = true;
          } else if (event.url.includes("design") && !event.url.includes("services")) {
            this.step4 = true;
          } else if (event.url.includes("review-box")) {
            this.step5 = true;
          } else if (event.url.includes("cart")) {
            this.step6 = true;
          }
        })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  resetSteps() {
    this.step0 = false;
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.step5 = false;
    this.step6 = false;
  }

  gotoStart() {
    setTimeout(() => {
      this.back.nativeElement.submit();
    });
  }

  goToBoxStyles() {
    if (!this.state().isEdit && !this.state().IsReorder) {
      if (this.step3 || this.step4 || this.step5 || this.step6) {
        if (this.state().subcategoryCode.startsWith("RPM")) {
          this.gotoStart();
        } else {
          this.router.navigate(["/box-style", this.state().categoryCode, this.state().uploadDesign], {
            queryParams: { sessionID: this.state().sessionKey },
          });
        }
      }
    }
  }

  goToBoxOptions() {
    if (!this.state().isEdit && this.state().IsReorder) {
      if (this.step4 || this.step5 || this.step6) {
        this.router.navigate([
          "/box-options",
          this.state().categoryCode,
          this.state().subcategoryCode,
          this.state().uploadDesign,
        ]);
      }
    }
  }

  goToDesigner() {
    if (!this.state().IsReorder) {
      if (this.step5 || this.step6) {
        this.router.navigate(["/design"]);
      }
    }
  }
}
