import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  signal,
  Signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { FormsModule } from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { AlertModule } from "ngx-bootstrap/alert";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { PopoverModule } from "ngx-bootstrap/popover";
import { LoadingModalComponent } from "../../features/loading-modal/loading-modal.component";
import { User } from "../../../../../libs/models/src/lib/user";
import { Preset } from "../../../../../libs/models/src/lib/box";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { environment } from "../../../environments/environment";
import { ERROR_BODY, ERROR_TITLE } from "../../../globals";
import { ConfirmationModalComponent } from "../../features/confirmation-modal/confirmation-modal.component";
import { BoxService } from "../../services/box.service";
import { CheckoutService } from "../../services/checkout.service";
import { ContainerService } from "../../services/container.service";
import { DesignService } from "../../services/design.service";
import Fractions from "../../../../../libs/utils/src/lib/fractions";
import { PrintingTableComponent } from "./printing-table/printing-table.component";
import { PageContentWrapperComponent } from "../../features/page-content-wrapper/page-content-wrapper.component";
import { ConnectedBetterComponent } from "../../features/connected-better/connected-better.component";
import { ClientSessionStateService } from "../../services/sessionState.service";
import { ClientSessionStateModel } from "../../models/sessionState.model";
import { Subscription } from "rxjs";
import { CustomerChoices } from "../../models/customer-choices.model.ts";
import { DesignModel } from "../../models/design.model";
import { ProductDetailsModel } from "../../models/product-details.model";
import { FXAuthService } from "../../services/fx-auth.service";
import { AuthService } from "../../../../../libs/services/src/lib/auth.service";
import { AUTH_SVC, ENVIRONMENT } from "../../../../../libs/models/src/lib/injection.tokens";
import debounce from "lodash/debounce";
import { Price } from "../../../../../libs/models/src/lib/price";

declare var window: any;

@Component({
  selector: "app-box-options",
  standalone: true,
  templateUrl: "./box-options.component.html",
  styleUrls: ["./box-options.component.scss"],
  imports: [
    CommonModule,
    AlertModule,
    FormsModule,
    ButtonsModule,
    PopoverModule,
    AlertModule,
    PdfJsViewerModule,
    AccordionModule,
    MatSelectModule,
    MatOptionModule,
    LoadingModalComponent,
    PrintingTableComponent,
    PageContentWrapperComponent,
    ConnectedBetterComponent,
  ],
})
export class BoxOptionsComponent implements OnInit, OnDestroy {
  @ViewChild("connected", { static: false }) connectedModal!: TemplateRef<any>;
  @ViewChild("notFoundModal", { static: false })
  notFoundModal!: TemplateRef<any>;
  @ViewChild("back", { static: false }) back!: ElementRef;

  public pageLoaded = signal<boolean>(false);
  public templateLoading = signal<boolean>(false);
  public downloadingTemplate = signal<boolean>(false);
  public mainGalleryImageURL = signal<string>("");
  public customSizeIncrease = signal<number>(0);

  private updating!: boolean;
  private user: User | undefined;

  public paperTypes: any[] = [];

  public noPrinting = false;

  public quantityError = "";
  public quantities!: number[];
  public standardQuantities = [1, 10, 25, 50, 100, 250, 500, 1000, 1500, 2000, 5000, 10000, 12000];
  public luxeQuantities = [500, 1000, 2000, 3000, 5000, 8000, 10000];

  public hasViolationErrors = false;

  public showCustomQuantity!: boolean;
  public design: DesignModel | undefined = undefined;
  public designNamed!: boolean;

  private modal: BsModalRef | undefined;
  public quantityArrayIndex: number = 0;
  public allowCustomSizes!: boolean;
  public displayPremiumWhite!: boolean;
  public displayWhite!: boolean;
  public displayKraft!: boolean;
  public displaySecondaryWhite!: boolean;
  public displaySecondaryKraft!: boolean;
  public displayTwoSided!: boolean;
  public displayLuxe!: boolean;
  public displayNoprint!: boolean;
  public displayStandard!: boolean;
  public allowCustomSample!: boolean;
  public displayFinish: any;
  public suggestedPreset: any;
  public customSizeActive = false;
  public customSizeEntered = false;
  public presetSuggestion = false;
  public depthError: any;
  public paperTypeId: string | undefined;
  public isDHC = false;
  public isPizza = false;
  public isPaddedMailer = false;
  public customQuantityViolation = false;
  public hasVariants = false;

  public customQuantityActive = false;
  public customQuantityEntered = false;
  public templateDownloadUrl!: SafeUrl;

  public categoryName: string = "";

  options!: string;
  state!: Signal<ClientSessionStateModel>;
  subscriptions: Subscription = new Subscription();
  specs = signal<CustomerChoices>(new CustomerChoices());

  lists: any = {
    variants: signal<any[]>([]),
    presets: signal<any[]>([]),
    printingOptions: signal<any[]>([]),
    outerMaterialOptions: signal<any[]>([]),
    innerMaterialOptions: signal<any[]>([]),
    finishOptions: signal<any[]>([]),
    prices: signal<any[]>([]),
    violations: signal<any[]>([]),
    presetMatches: signal<any[]>([]),
    boardTypeList: signal<any[]>([]),
    boardStrengthList: signal<any[]>([]),
    images: signal<any[]>([]),
    infoDataRows: signal<any[]>([]),
    noteDataRows: signal<any[]>([]),
  };

  supportPhone: string = this.env.config.supportPhone;
  supportEmail: string = "mailto:" + this.env.config.supportEmail;

  constructor(
    private boxService: BoxService,
    private route: ActivatedRoute,
    private bsModalService: BsModalService,
    private router: Router,
    private logger: NGXLogger,
    private containerService: ContainerService,
    private designService: DesignService,
    private sanitizer: DomSanitizer,
    private checkoutService: CheckoutService,
    private stateSvc: ClientSessionStateService,
    @Inject(AUTH_SVC) private authService: AuthService,
    @Inject(ENVIRONMENT) private env: any
  ) {}

  async ngOnInit() {
    window.scroll(0, 0);
    this.pageLoaded.set(false);
    this.state = this.stateSvc.getState();
    this.subscriptions.add(
      this.route.queryParamMap.subscribe(async (params) => {
        const sessionKey: string = params.get("sessionID") ?? params.get("sessionId") ?? "";
        if (sessionKey) {
          await this.returnFromLogin();
        }
      })
    );
    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        const category: string = params.get("categoryCode") ?? "";
        if (category) {
          this.stateSvc.updateState({ categoryCode: category });
          const subcategory: string = params.get("subcategoryCode") ?? "";
          if (subcategory) {
            this.stateSvc.updateState({ subcategoryCode: subcategory });
          }
          const uploadDesign = Boolean(JSON.parse(params.get("uploadDesign") ?? "false"));
          if (uploadDesign !== this.state().uploadDesign) {
            this.stateSvc.updateState({ uploadDesign: uploadDesign });
          }
          this.initPresets();
          // for "back" support
          // special handling required to match select boxes
          if (this.state().optionSpecs) {
            setTimeout(() => {
              this.specs.set(this.state().optionSpecs!);
              this.updateForm();
            }, 1000);
          }
        }
      })
    );
  }

  private async returnFromLogin() {
    this.stateSvc.refreshState();
    this.specs.set(this.state().optionSpecs!);
    this.updateLists("images", this.specs().selectedSubcategory?.images);
    this.paperTypes = this.boxService.getPaperTypes(this.specs().selectedSubcategory!);

    if (!!this.state().targetService) {
      (this as any)[this.state().targetService]();
    }
  }

  private initPresets() {
    const subcategoryCode: string = this.state().subcategoryCode;
    this.isPaddedMailer = subcategoryCode.startsWith("RPM");
    switch (subcategoryCode) {
      case "DHC":
        this.isDHC = true;
        this.getDHC();
        break;
      default:
        this.isPizza = subcategoryCode === "PIZ";
        this.getPresets();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  goBack() {
    if (!this.isPaddedMailer) {
      this.router.navigate(["/box-style", this.state().categoryCode, this.state().uploadDesign], {
        queryParams: { sessionID: this.state().sessionKey },
      });
    } else if (this.state().referer) {
      setTimeout(() => {
        this.back.nativeElement.submit();
      });
    }
  }

  async getPresets() {
    if (this.state().subcategoryCode.toLowerCase().includes("snap2") && this.specs().selectedVariant) {
      this.stateSvc.updateState({
        subcategoryCode: this.specs().selectedVariant?.code,
      });
    }
    const presets = await this.boxService.listPresets(this.state().categoryCode, this.state().subcategoryCode);
    this.updateLists("presets", presets);
    const preset = presets[0];
    if (this.lists.presets().length > 0) {
      this.mainGalleryImageURL.set(preset.type?.subcategory?.images[0].location ?? "");
      // Sets default box photo.
      for (const image of preset.type!.subcategory!.images) {
        if (image.default) {
          this.mainGalleryImageURL.set(image.location ?? "");
          break;
        }
      }
    }

    this.customSizeActive = false;
    this.customSizeEntered = false;
    this.updateSpecs("selectedPreset", preset);
    this.categoryName = preset?.type?.description ?? "";
    const subcategory = preset?.type?.subcategory;
    this.updateSpecs("selectedSubcategory", subcategory);
    this.updateLists("images", subcategory?.images);
    this.updateLists(
      "infoDataRows",
      subcategory?.data.filter((value: any) => value.type === "INFO")
    );
    this.updateLists(
      "noteDataRows",
      subcategory?.data.filter((value: any) => value.type === "NOTE")
    );
    this.updateSpecs("selectedBoardType", preset?.type?.boardType);

    this.paperTypes = this.boxService.getPaperTypes(subcategory!);

    this.updatePrintingOptions();
    this.updateSpecs("luxe", false);
    this.updateSpecs("twoSided", false);
    this.updateSpecs("selectedFinish", "Matte");
    this.updateLists("boardTypeList", subcategory?.boardTypes);
    this.updateSpecs(
      "selectedBoardType",
      this.lists.boardTypeList().find((boardType: any) => boardType.id === preset?.type?.boardType?.id)
    );

    this.updateSpecs("selectedBoardStrength", this.specs().selectedBoardType?.boardStrengths[0]);
    this.allowCustomSizes = preset?.allowCustomSizes ?? false;
    this.displayPremiumWhite = preset?.displayPremiumWhite ?? false;
    this.displayWhite = preset?.displayWhite ?? false;
    this.displayKraft = preset?.displayKraft ?? false;
    this.displaySecondaryWhite = preset?.displaySecondaryWhite ?? false;
    this.displaySecondaryKraft = preset?.displaySecondaryKraft ?? false;
    this.displayTwoSided = preset?.displayTwoSided ?? false;
    this.displayLuxe = preset?.displayLuxe ?? false;
    this.displayStandard = preset?.displayStandard ?? false;
    this.displayNoprint = preset?.displayNoprint ?? false;
    this.allowCustomSample = preset?.allowCustomSample ?? false;

    if (preset?.hasVariants) {
      this.getVariants();
    }
    this.updateForm();
    this.pageLoaded.set(true);
  }

  async getDHC() {
    const subcategory = await this.boxService.getSubcategory(this.state().subcategoryCode);
    this.pageLoaded.set(true);
    this.updateSpecs("selectedSubcategory", subcategory);
    this.updateLists("images", subcategory?.images);
    this.customSizeActive = true;
    this.customSizeEntered = false;
    this.updateSpecs("luxe", false);
    this.updateSpecs("twoSided", false);
    this.updateSpecs("length", 8);
    this.updateSpecs("lengthWhole", 8);
    this.updateSpecs("width", 4);
    this.updateSpecs("widthWhole", 4);
    this.updateSpecs("depth", 0);
    this.updateLists("presets", []);
    this.updateLists("printingOptions", []);
    this.updateLists("finishOptions", ["Matte"]);
    this.updateSpecs("selectedFinish", "Matte");
    this.updateLists("boardTypeList", subcategory.boardTypes);
    this.updateSpecs("selectedBoardType", this.lists.boardTypeList()[0]);
    this.updateSpecs("selectedBoardStrength", this.lists.boardTypeList()[0].boardStrengths[0]);
    this.mainGalleryImageURL.set(subcategory.images[0].location ?? "");
    this.updateSpecs("selectedBoardStrength", subcategory?.boardTypes[0].boardStrengths[0]);
    this.allowCustomSizes = subcategory?.allowCustomSizes ?? false;
    this.displayPremiumWhite = subcategory?.displayPremiumWhite ?? false;
    this.displayWhite = subcategory?.displayWhite ?? false;
    this.displayKraft = subcategory?.displayKraft ?? false;
    this.displaySecondaryWhite = subcategory?.displaySecondaryWhite ?? false;
    this.displaySecondaryKraft = subcategory?.displaySecondaryKraft ?? false;
    this.displayTwoSided = subcategory?.displayTwoSided ?? false;
    this.displayLuxe = subcategory?.displayLuxe ?? false;
    this.displayStandard = subcategory?.displayStandard ?? false;
    this.allowCustomSample = subcategory?.allowCustomSample ?? false;

    // Sets default box photo.
    for (const image of subcategory.images) {
      if (image.default) {
        this.mainGalleryImageURL.set(image.location ?? "");
        break;
      }
    }
    this.updateForm();
    this.listPrices();
  }

  cancel() {
    this.modal?.hide();
  }

  private getVariants() {
    if (this.state().subcategoryCode === "SNAP2S" || this.state().subcategoryCode === "SNAP2RS") {
      this.updateSpecs("luxe", true);
      this.options = "superseal";
    }
    if (!this.specs().selectedVariant) {
      this.boxService.getVariants(this.specs().selectedSubcategory?.id!).then(
        (value) => {
          this.updateLists("variants", value);
          this.updateSpecs("selectedVariant", this.lists.variants()[0]);
        },
        (reason) => {
          this.showModal(this.notFoundModal);
          this.logger.error(reason);
        }
      );
    } else {
      this.updateSpecs("selectedPreset", undefined);
    }
  }

  updateForm() {
    debounce(() => this._updateForm(), 100)();
  }

  private _updateForm() {
    {
      // this.updateSpecs("selectedPrice", undefined);
      if (this.state().subcategoryCode === "DHC") {
        this.categoryName = "Display Header Card";
      } else {
        this.categoryName = this.specs().selectedPreset?.type?.name + " " + this.specs().selectedBoardType?.name;
      }

      if (!this.customSizeEntered) {
        this.updateSpecs("length", this.specs().selectedPreset?.length ?? 0);
        this.updateSpecs("lengthWhole", this.getWholeNumber(this.specs().length));
        this.updateSpecs("lengthFractions", this.convertFractionToDecimal(this.specs().length));

        this.updateSpecs("width", this.specs().selectedPreset?.width ?? 0);
        this.updateSpecs("widthWhole", this.getWholeNumber(this.specs().width));
        this.updateSpecs("widthFractions", this.convertFractionToDecimal(this.specs().width));

        this.updateSpecs("depth", this.specs().selectedPreset?.depth ?? 0);
        this.updateSpecs("depthWhole", this.getWholeNumber(this.specs().depth));
        this.updateSpecs("depthFractions", this.convertFractionToDecimal(this.specs().depth));
      } else {
        this.updateSpecs(
          "length",
          this.specs().lengthWhole + this.convertFractionToDecimal(this.specs().lengthFraction)
        );
        this.updateSpecs("width", this.specs().widthWhole + this.convertFractionToDecimal(this.specs().widthFraction));
        this.updateSpecs("depth", this.specs().depthWhole + this.convertFractionToDecimal(this.specs().depthFraction));
      }

      this.updateLists("boardStrengthList", this.specs().selectedBoardType?.boardStrengths);
      this.updateSpecs(
        "selectedBoardStrength",
        this.lists.boardStrengthList().find((value: any) => value.code === this.specs().selectedBoardStrength?.code)
      );
      if (!this.specs().selectedBoardStrength) {
        this.updateSpecs("selectedBoardStrength", this.lists.boardStrengthList()[0]);
      }
      this.noPrinting = false;
      this.updatePrintingOptions();
      this.updateMaterialOptions();
      if (this.customQuantityActive) {
        this.updateSpecs("enteredCustomQuantity", this.specs().quantity.toString().split("(")[0]);
        if (
          this.getPaperTypeID(this.specs().outerMaterial) === "pkw" ||
          this.getPaperTypeID(this.specs().innerMaterial) === "pkw"
        ) {
          this.paperTypeId = "pkw";
        } else if (this.state().categoryCode === "Event Boxes") {
          this.paperTypeId = this.getPaperTypeID(this.specs().outerMaterial);
        } else if (
          this.getPaperTypeID(this.specs().outerMaterial) === "kw" &&
          this.getPaperTypeID(this.specs().innerMaterial) === "k"
        ) {
          this.paperTypeId = "kw";
        } else if (
          this.getPaperTypeID(this.specs().outerMaterial) === "k" &&
          this.getPaperTypeID(this.specs().innerMaterial) === "kw"
        ) {
          this.paperTypeId = "kw";
        } else if (
          this.getPaperTypeID(this.specs().outerMaterial) === "k" ||
          this.getPaperTypeID(this.specs().innerMaterial) === "k"
        ) {
          this.paperTypeId = "k";
        } else {
          this.paperTypeId = "kw";
        }
        this.checkForViolations();
      }
      if (this.isPizza) {
        this.listPizzaPrices();
      } else if (this.isPaddedMailer) {
        this.listPaddedMailerPrices();
      } else {
        this.listPrices();
      }
    }
  }

  // Called from the custom size container. Takes the length entered and fraction selected
  // And creates a respective length, width, and depth and then calls pricing function.
  checkForPresets() {
    this.updateSpecs("length", this.specs().lengthWhole + this.convertFractionToDecimal(this.specs().lengthFraction));
    this.updateSpecs;
    if (!this.isDHC) {
      this.updateSpecs("depth", this.specs().depthWhole + this.convertFractionToDecimal(this.specs().depthFraction));
    } else {
      this.updateSpecs("depth", this.convertFractionToDecimal(this.specs().depthFraction));
    }
    if (this.specs().selectedPrice) {
      this.updateSpecs("quantity", this.specs().selectedPrice?.quantity ?? 0);
    } else {
      if (this.specs().printing!.indexOf("Standard") >= 0) {
        this.updateSpecs("quantity", 100);
      } else {
        this.updateSpecs("quantity", 1000);
      }
    }

    this.listPrices();
  }

  presetSuggestionSelected(preset: Preset) {
    // After user selects the suggest preset instead of custom sizing,
    // find the preset's ID in the existing presetsList and set that as the selectedPreset.
    this.lists.presets().forEach((_item: any, index: number) => {
      if (preset.id === this.lists.presets()[index].id) {
        this.specs.update((s) => ({
          ...s,
          selectedPreset: this.lists.presets()[index],
        }));
      }
    });
    this.customSizeEntered = false;
    this.updateForm();
  }

  checkForViolations() {
    if(this.updating) return;

    if (this.specs().enteredCustomQuantity) {
      this.updateSpecs("quantity", parseInt(this.specs().enteredCustomQuantity));
    } else {
      this.updateSpecs("enteredCustomQuantity", this.specs().quantity);
    }

    let paperTypeName = "white";
    if (this.specs().outerMaterial === "Kraft" || this.specs().outerMaterial === "k") {
      paperTypeName = "kraft";
    }

    if (this.isPaddedMailer) {
      this.boxService
        .getPaddedMailerPrice(
          this.specs().selectedPreset?.type?.code ?? "",
          paperTypeName,
          this.specs().quantity,
          this.noPrinting
        )
        .then((value: any) => {
          // If custom quantity is 1 then it is considered a sample and is allowed

          let tmp = parseInt(this.specs().enteredCustomQuantity);
          this.customQuantityViolation =
            tmp !== 1 && (tmp! < this.lists.prices()[0]!.minimumOrder || tmp > this.lists.prices()[0]!.maxQuantity);

          if (!this.customQuantityViolation) {
            this.updateSpecs("selectedPrice", value.price);
            this.updateSpecs(
              "enteredCustomQuantity",
              tmp +
                ` cases of ${
                  this.specs().selectedPrice?.unitsPerCase
                } ($${this.specs().selectedPrice?.pricePerUnit!.toFixed(2)}) each`
            );

            this.updateSpecs("quantity", tmp);
          }
        })
        .catch(() => {
          this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    } else {
      this.boxService
        .getPrice(
          this.boxCode ?? "",
          this.specs().selectedBoardStrength?.strength ?? "",
          this.paperTypeId ?? "",
          this.specs().length,
          this.specs().width,
          this.specs().depth,
          this.specs().quantity,
          this.specs().twoSided,
          this.specs().luxe,
          this.specs().selectedFinish === "Gloss",
          this.noPrinting,
          this.options ?? "",
          false
        )
        .then((priceData) => {
          const tmp = parseInt(this.specs().enteredCustomQuantity);
          this.updateSpecs("selectedPriceDataBox", priceData);
          if (!this.specs().luxe) {
            this.customQuantityViolation =
              (tmp < (this.specs().selectedPriceDataBox?.boxType?.minimumOrder ?? 0) ||
                tmp > (this.specs().selectedPriceDataBox?.boxType?.maxQuantity ?? 0)) &&
              (this.specs().enteredCustomQuantity !== "1" ||
                this.state().subcategoryCode === "SNAP2" ||
                this.state().subcategoryCode === "SNAP2R");
          } else {
            this.customQuantityViolation =
              (tmp < (this.specs().selectedPriceDataBox?.boxType?.luxeMinimumOrder ?? 0) ||
                tmp > (this.specs().selectedPriceDataBox?.boxType?.maxQuantity ?? 0)) &&
              (this.specs().enteredCustomQuantity !== "1" ||
                this.state().subcategoryCode === "SNAP2" ||
                this.state().subcategoryCode === "SNAP2R");
          }
          if (this.state().subcategoryCode === "PIZ" && this.specs().enteredCustomQuantity === "1") {
            this.customQuantityViolation = true;
          }

          if (!this.customQuantityViolation) {
            this.updateSpecs("selectedPrice", priceData.price);
            this.updateSpecs("quantity", priceData!.price!.quantity);

            this.updateSpecs(
              "enteredCustomQuantity",
              this.specs().quantity + ` ($${this.specs().selectedPrice?.pricePerUnit?.toFixed(2)}) each`
            );
          }
        })
        .catch(() => {
          this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    }
  }

  numberOnly(event: { which: any; keyCode: any }) {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  public trackByFn(index: any, item: any) {
    if (!item) {
      return index;
    } else {
      //return item.id;
      return index;
    }
  }

  listPrices() {
    this.updateLists("prices", []);
    this.updating = true;
    if (
      this.getPaperTypeID(this.specs().outerMaterial) === "pkw" ||
      this.getPaperTypeID(this.specs().innerMaterial) === "pkw"
    ) {
      this.paperTypeId = "pkw";
    } else if (this.state().categoryCode === "Event Boxes") {
      this.paperTypeId = this.getPaperTypeID(this.specs().outerMaterial);
    } else if (
      this.getPaperTypeID(this.specs().outerMaterial) === "kw" &&
      this.getPaperTypeID(this.specs().innerMaterial) === "k"
    ) {
      this.paperTypeId = "kw";
    } else if (
      this.getPaperTypeID(this.specs().outerMaterial) === "k" &&
      this.getPaperTypeID(this.specs().innerMaterial) === "kw"
    ) {
      this.paperTypeId = "kw";
    } else if (
      this.getPaperTypeID(this.specs().outerMaterial) === "k" ||
      this.getPaperTypeID(this.specs().innerMaterial) === "k"
    ) {
      this.paperTypeId = "k";
    } else {
      this.paperTypeId = "kw";
    }

    this.quantities = [...this.standardQuantities];
    if (this.specs().printing!.indexOf("Standard") < 0) {
      if (this.specs().printing!.indexOf("No Printing") >= 0) {
        if (
          this.specs().printing === "Blank Boxes | No Printing" &&
          (this.state().subcategoryCode === "SNAP2" || this.state().subcategoryCode === "SNAP2R")
        ) {
          this.updateSpecs("luxe", true);
        }
      } else if (this.specs().luxe) {
        this.quantities = [...this.luxeQuantities];
      }
    }

    if (
      (this.specs().printing?.includes("Luxe") || this.specs().printing === "Blank Boxes | No Printing") &&
      this.state().subcategoryCode === "SNAP2"
    ) {
      this.updateSpecs("luxe", true);
      this.options = "";
    } else if (
      (this.specs().printing?.includes("Luxe") || this.specs().printing === "Blank Boxes | No Printing") &&
      this.state().subcategoryCode === "SNAP2S"
    ) {
      this.updateSpecs("luxe", true);
    }
    if (
      (this.specs().printing?.includes("Luxe") || this.specs().printing === "Blank Boxes | No Printing") &&
      this.state().subcategoryCode === "SNAP2R"
    ) {
      this.updateSpecs("luxe", true);
      this.options = "";
    } else if (
      (this.specs().printing?.includes("Luxe") || this.specs().printing === "Blank Boxes | No Printing") &&
      this.state().subcategoryCode === "SNAP2RS"
    ) {
      this.updateSpecs("luxe", true);
    }
    this.boxService
      .listPrices(
        this.boxCode ?? "",
        this.specs().selectedBoardStrength?.strength ?? "",
        this.paperTypeId,
        this.specs().length,
        this.specs().width,
        this.specs().depth,
        this.quantities,
        this.specs().twoSided,
        this.specs().luxe,
        this.noPrinting,
        this.options ?? "",
        false
      )
      .then(
        (priceData) => {
          if (priceData.prices.length > 0 && !this.customQuantityActive) {
            if (!this.quantityArrayIndex) {
              if (this.specs().printing!.indexOf("Standard") >= 0) {
                this.updateSpecs("selectedPrice", priceData.prices[4]);
              } else if (this.specs().printing!.indexOf("No Printing") >= 0) {
                this.updateSpecs("selectedPrice", priceData.prices[4]);
              } else {
                const defaultSelection =
                  priceData.prices.find((x: Price) => x.quantity === priceData.defaultQuantity) ?? priceData.prices[0];
                this.updateSpecs("selectedPrice", defaultSelection);
              }
              // Default SNAP2 quantity to 1000
              if (this.state().subcategoryCode.startsWith("SNAP2")) {
                this.updateSpecs("selectedPrice", priceData.prices[4]);
              }
            } else {
              this.updateSpecs("selectedPrice", priceData.prices[this.quantityArrayIndex]);
            }
            this.updateSpecs("quantity", this.specs().selectedPrice!.quantity);
          }
          this.updateSpecs("selectedPriceDataBox", priceData.box);
          if (this.specs().selectedPriceDataBox?.boxType?.code === "RSCB") {
            if (
              this.specs().selectedPriceDataBox &&
              this.specs().selectedPriceDataBox?.dimensions &&
              (this.specs().selectedPriceDataBox?.dimensions?.length ?? 0) > 2.9375 &&
              (this.specs().selectedPriceDataBox?.dimensions?.width ?? 0) > 2.9375 &&
              (this.specs().selectedPriceDataBox?.dimensions?.depth ?? 0) > 2.9375
            ) {
              priceData.box.violations = priceData.box.violations.filter((x) => !x.rule?.includes("Rule15"));
            } else {
              priceData.box.violations.map((x) => {
                if (x.rule?.includes("Rule15")) {
                  x.message = "Please call to order";
                }
                return x;
              });
            }
            //
          }
          this.updateLists("violations", priceData.box.violations);
          if (!this.specs().luxe) {
            priceData.prices = priceData.prices.filter(
              (x) =>
                (x.quantity >= priceData.prices[0].minimumOrder && x.quantity <= priceData.prices[0].maxQuantity) ||
                (this.specs().selectedSubcategory?.allowCustomSample && x.quantity === 1)
            );
          } else {
            priceData.prices = priceData.prices.filter(
              (x) =>
                x.quantity >= priceData.prices[0].luxeMinimumOrder ||
                (this.specs().selectedSubcategory?.allowCustomSample && x.quantity === 1)
            );
          }

          if (this.specs().selectedSubcategory?.allowCustomSample) {
            priceData.prices.push();
          }
          this.updateLists("prices", priceData.prices);
          this.updateLists("presetMatches", priceData.presetMatches);
          this.customSizeIncrease.set(priceData.customSizeIncrease / 100);
          const something = priceData.box.violations.find((violation) => {
            if (this.state().subcategoryCode === "OPF") {
              return !violation.warning;
            }
            return violation.error;
          });

          this.hasViolationErrors = something !== undefined;
          this.customQuantityViolation = false;
          this.updating = false;
        },
        (reason) => {
          this.showModal(this.notFoundModal);
          this.logger.error(reason);
          this.updateLists("prices", []);
          this.updating = false;
        }
      );
  }

  listPizzaPrices() {
    this.quantities = [
      500, 750, 1000, 1250, 1500, 1750, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000,
    ];
    this.updateSpecs("luxe", true);
    this.updateLists("prices", []);
    this.updating = true;
    if (
      this.getPaperTypeID(this.specs().outerMaterial) === "pkw" ||
      this.getPaperTypeID(this.specs().innerMaterial) === "pkw"
    ) {
      this.paperTypeId = "pkw";
    } else if (this.state().categoryCode === "Event Boxes") {
      this.paperTypeId = this.getPaperTypeID(this.specs().outerMaterial);
    } else if (this.getPaperTypeID(this.specs().outerMaterial) === "k") {
      this.paperTypeId = "k";
    } else {
      this.paperTypeId = "kw";
    }

    this.boxService
      .listPrices(
        this.boxCode ?? "",
        this.specs().selectedBoardStrength?.strength ?? "",
        this.paperTypeId,
        this.specs().length,
        this.specs().width,
        this.specs().depth,
        this.quantities,
        this.specs().twoSided,
        this.specs().luxe,
        this.noPrinting,
        this.options,
        false
      )
      .then(
        (priceData) => {
          if (priceData.prices.length > 0) {
            if (!this.quantityArrayIndex) {
              const defaultSelection =
                priceData.prices.find((x: Price) => x.quantity === priceData.defaultQuantity) ?? priceData.prices[0];
              if (this.specs().printing!.indexOf("Standard") >= 0) {
                this.updateSpecs("selectedPrice", priceData.prices[4]);
              } else {
                this.updateSpecs("selectedPrice", defaultSelection);
              }
              // Default SNAP2 quantity to 1000
              if (this.state().subcategoryCode === "SNAP2") {
                this.updateSpecs("selectedPrice", defaultSelection);
              }
              if (this.state().subcategoryCode === "SNAP2S") {
                this.updateSpecs("selectedPrice", defaultSelection);
              }
              if (this.state().subcategoryCode === "SNAP2R") {
                this.updateSpecs("selectedPrice", defaultSelection);
              }
              if (this.state().subcategoryCode === "SNAP2RS") {
                this.updateSpecs("selectedPrice", defaultSelection);
              }
            } else {
              this.updateSpecs("selectedPrice", priceData.prices[this.quantityArrayIndex]);
            }
            this.updateSpecs("quantity", this.specs().selectedPrice!.quantity);
          }
          this.updateSpecs("selectedPriceDataBox", priceData.box);

          this.updateLists("violations", priceData.box.violations);
          if (!this.specs().luxe) {
            priceData.prices = priceData.prices.filter(
              (x) =>
                (x.quantity >= priceData.prices[0].minimumOrder && x.quantity <= priceData.prices[0].maxQuantity) ||
                (this.specs().selectedSubcategory?.allowCustomSample && x.quantity === 1)
            );
          } else {
            priceData.prices = priceData.prices.filter(
              (x) =>
                x.quantity >= priceData.prices[0].luxeMinimumOrder ||
                (this.specs().selectedSubcategory?.allowCustomSample && x.quantity === 1)
            );
          }

          if (this.specs().selectedSubcategory?.allowCustomSample) {
            priceData.prices.push();
          }
          this.updateLists("prices", priceData.prices);
          this.updateLists("presetMatches", priceData.presetMatches);
          this.customSizeIncrease.set(priceData.customSizeIncrease / 100);
          const something = priceData.box.violations.find((violation) => {
            return !violation.warning;
          });

          this.hasViolationErrors = something !== undefined;
          this.updating = false;
        },
        (reason) => {
          this.showModal(this.notFoundModal);
          this.logger.error(reason);
          this.updateLists("prices", []);
          this.updating = false;
        }
      );
  }

  listPaddedMailerPrices() {
    switch (this.specs().selectedPreset?.type?.code) {
      case "RPM00Z":
        this.quantities = [1, 2, 10, 20, 100];
        break;
      case "RPM02Z":
      case "RPM05Z":
        this.quantities = [1, 5, 20, 50, 100, 200];
        break;
      case "RPM06Z":
        this.quantities = [1, 10, 50, 150, 250, 500];
        break;
    }

    if (this.getPaperTypeID(this.specs().material) === "kw") {
      this.paperTypeId = "w";
    }
    this.noPrinting = (this.specs().printing === "Blank Mailers | No Printing");

    const paperType = this.specs().outerMaterial === "Kraft" || this.specs().outerMaterial === "k" ? "kraft" : "white";
    this.boxService
      .listPaddedMailerPrices(
        this.specs().selectedPreset?.type?.code ?? "",
        paperType,
        this.quantities,
        this.noPrinting
      )
      .then((priceData: any) => {
        this.pageLoaded.set(true);

        if (priceData.prices.length > 0 && !this.customQuantityActive) {
          // priceData.prices = priceData.prices.filter(price => price);
          const defaultSelection =
            priceData.prices.find((x: Price) => x.quantity === priceData.defaultQuantity) ?? priceData.prices[0];

          this.updateSpecs("selectedPrice", defaultSelection);
          this.updateSpecs("quantity", defaultSelection.quantity);
        }
        this.updateLists("prices", priceData.prices);
        this.updateLists("presetMatches", priceData.presetMatches);
        this.customSizeIncrease.set(priceData.customSizeIncrease);
        if (!this.specs().selectedBoardType) {
          this.updateSpecs("selectedBoardType", this.lists.boardTypeList()[0]);
        }

        this.updating = false;
      })
      .catch(() => {
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  getPaperTypeID(paperType: string) {
    if (paperType === "Kraft") {
      return "k";
    } else if (paperType === "Premium White") {
      return "pkw";
    } else {
      return "kw";
    }
  }

  get boxCode() {
    let code = this.specs().selectedSubcategory?.code;

    if (this.specs().selectedBoardType) {
      code += this.specs().selectedBoardType?.flute ?? "";
    }
    if (this.specs().selectedPreset && !this.specs().selectedSubcategory?.allowCustomSizes) {
      code = this.specs().selectedPreset?.type?.code;
    }

    return code;
  }

  get luxePrinting() {
    return this.specs().selectedPrintType && this.specs().selectedPrintType?.id === "luxe";
  }

  updatePrintingOptions() {
    const printingOptions = [];

    if (this.displayStandard) {
      printingOptions.push("Standard | Outside");
      if (this.displayTwoSided) {
        printingOptions.push("Standard | Inside", "Standard | 2-Sided");
      }
    }
    if (this.displayLuxe) {
      printingOptions.push("Luxe | Outside");
      if (this.displayTwoSided) {
        printingOptions.push("Luxe | Inside", "Luxe | 2-Sided");
      }
    }
    if (this.displayNoprint) {
      if (this.isPaddedMailer) {
        printingOptions.push("Blank Mailers | No Printing");
      } else {
        printingOptions.push("Blank Boxes | No Printing");
      }
    }
    this.updateLists("printingOptions", printingOptions);
    // If printing not selected yet, default to first option.
    if (!this.specs().printing && printingOptions.length > 0) {
      this.updateSpecs("printing", printingOptions[0]);
      this.printingSelected(this.specs().printing);
    }
  }

  printingSelected(printingType: string | string[] | undefined) {
    this.updateSpecs("printing", printingType);
    const standard = this.specs().printing!.indexOf("Standard") >= 0;
    if (this.specs().printing === "Standard | Outside") {
      // If changing from Luxe -> Standard, reset quantity to default.
      this.updateSpecs("insideOnly", false);
      if (this.specs().luxe) {
        this.quantityArrayIndex = 2;
      }
      this.updateSpecs("luxe", false);
      this.updateSpecs("twoSided", false);
      this.updateSpecs("insideOnly", false);
    } else if (this.specs().printing === "Standard | Inside") {
      this.updateSpecs("insideOnly", true);
      // If changing from Luxe -> Standard, reset quantity to default.
      if (this.specs().luxe) {
        this.quantityArrayIndex = 2;
      }
      this.updateSpecs("luxe", false);
      this.updateSpecs("twoSided", false);
      this.updateSpecs("insideOnly", true);
    } else if (this.specs().printing === "Blank Boxes | No Printing") {
      this.updateSpecs("insideOnly", false);
      this.updateSpecs("luxe", false);
      this.updateSpecs("twoSided", false);
      this.updateSpecs("insideOnly", false);
    } else if (this.specs().printing === "Standard | 2-Sided") {
      this.updateSpecs("insideOnly", false);
      // If changing from Luxe -> Standard, reset quantity to default.
      if (this.specs().luxe) {
        this.quantityArrayIndex = 2;
      }
      this.updateSpecs("twoSided", true);
      this.updateSpecs("insideOnly", false);
      this.updateSpecs("luxe", false);
    } else if (this.specs().printing === "Luxe | Outside") {
      this.updateSpecs("insideOnly", false);
      // If changing from Standard -> Luxe, reset quantity to default.
      if (standard) {
        this.quantityArrayIndex = 0;
      }
      this.updateSpecs("luxe", true);
      this.updateSpecs("twoSided", false);
      this.updateSpecs("insideOnly", false);
    } else if (this.specs().printing === "Luxe | Inside") {
      this.updateSpecs("insideOnly", true);
      // If changing from Standard -> Luxe, reset quantity to default.
      if (standard) {
        this.quantityArrayIndex = 0;
      }
      this.updateSpecs("luxe", true);
      this.updateSpecs("twoSided", false);
      this.updateSpecs("insideOnly", true);
    } else if (this.specs().printing === "Luxe | 2-Sided") {
      this.updateSpecs("insideOnly", false);
      // If changing from Luxe -> Standard, reset quantity to default.
      if (this.specs().luxe) {
        this.quantityArrayIndex = 2;
      }
      this.updateSpecs("luxe", true);
      this.updateSpecs("twoSided", true);
      this.updateSpecs("insideOnly", false);
    }
    this.updateFinishOptions();
    this.updateForm();
  }

  updateMaterialOptions() {
    // Clear out materialOptions

    const materialOptions = [];
    const outerMaterialOptions = [];
    const innerMaterialOptions = [];

    if (this.displayWhite) {
      materialOptions.push("Standard White");
      if (this.isPaddedMailer) {
        outerMaterialOptions.push("White");
      } else {
        outerMaterialOptions.push("Standard White");
      }
      if (this.state().categoryCode !== "Event Boxes" && this.state().subcategoryCode !== "PIZ") {
        innerMaterialOptions.push("Standard White");
      }
    }

    if (this.displayPremiumWhite) {
      materialOptions.push("Premium White");

      // If inner material is premium white, remove this option from outer material
      if (this.specs().innerMaterial !== "Premium White" && this.state().subcategoryCode !== "PIZ") {
        outerMaterialOptions.push("Premium White");
      }
      // If outer material is premium white, remove this option from inner material
      if (
        this.specs().outerMaterial !== "Premium White" &&
        this.state().categoryCode !== "Event Boxes" &&
        this.state().subcategoryCode !== "PIZ"
      ) {
        innerMaterialOptions.push("Premium White");
      }
    }
    if (this.displayKraft) {
      materialOptions.push("Kraft");
      outerMaterialOptions.push("Kraft");
      innerMaterialOptions.push("Kraft");
    }
    if (this.displaySecondaryKraft && this.state().subcategoryCode === "PIZ") {
      innerMaterialOptions.push("Kraft");
    }

    // If outer material not selected yet, default to first option.
    if (!this.specs().outerMaterial && outerMaterialOptions.length > 0) {
      this.updateSpecs("outerMaterial", outerMaterialOptions[0]);
    }
    // If inner material not selected yet, default to first option.
    if (!this.specs().innerMaterial && innerMaterialOptions.length > 0) {
      this.updateSpecs("innerMaterial", this.lists.innerMaterialOptions()[0]);
    }
    this.updateLists("outerMaterialOptions", outerMaterialOptions);
    this.updateLists("innerMaterialOptions", innerMaterialOptions);
    this.updateFinishOptions();
  }

  updateFinishOptions() {
    const finishOptions = [];
    if (
      this.specs().luxe &&
      this.specs().printing === "Luxe | Inside" &&
      this.specs().innerMaterial === "Premium White"
    ) {
      this.updateSpecs("selectedFinish", "Gloss");
      finishOptions.push("Gloss");
    } else if (
      this.specs().luxe &&
      this.specs().printing === "Luxe | Outside" &&
      this.specs().outerMaterial === "Premium White"
    ) {
      this.updateSpecs("selectedFinish", "Gloss");
      finishOptions.push("Gloss");
    } else if (
      this.specs().luxe &&
      (this.specs().innerMaterial !== "Premium White" || this.specs().outerMaterial !== "Premium White")
    ) {
      this.updateSpecs("selectedFinish", "Matte");
      finishOptions.push("Matte");
    } else {
      finishOptions.push("Matte");
    }

    // Displays Finish if premium white is selected.

    if (this.specs().printing!.indexOf("Standard") >= 0 && this.specs().material === "Premium White") {
      finishOptions.push("Gloss");
    } else if (
      this.specs().printing!.indexOf("Standard") >= 0 &&
      (this.specs().innerMaterial === "Premium White" || this.specs().outerMaterial === "Premium White")
    ) {
      finishOptions.push("Gloss");
    } else if (
      this.specs().printing!.indexOf("No Printing") >= 0 &&
      (this.specs().innerMaterial === "Premium White" || this.specs().outerMaterial === "Premium White")
    ) {
      finishOptions.push("Gloss");
    }
    this.updateLists("finishOptions", finishOptions);
  }

  // Displays custom size fractions
  convertDecimalToFraction(num: any) {
    return Fractions.convertDecimalToFraction(num);
  }

  getWholeNumber(fraction: number) {
    const temp = String(fraction).split(".", 2);
    return parseFloat(temp[0]);
  }

  convertFractionToDecimal(fraction: number) {
    const temp = String(fraction).split(".", 2);
    return parseFloat("0." + temp[1]);
  }

  nextSubcategoryImage() {
    this.updateSpecs(
      "selectedImageIndex",
      (this.specs().selectedImageIndex + 1) % (this.specs().selectedSubcategory?.images?.length ?? 0)
    );
    this.mainGalleryImageURL.set(
      this.specs().selectedSubcategory?.images[this.specs().selectedImageIndex].location ?? ""
    );
  }

  prevSubcategoryImage() {
    const numImages = this.specs().selectedSubcategory?.images.length ?? 0;
    this.updateSpecs("selectedImageIndex", (this.specs().selectedImageIndex + numImages - 1) % numImages);
    this.mainGalleryImageURL.set(
      this.specs().selectedSubcategory?.images[this.specs().selectedImageIndex].location ?? ""
    );
  }

  selectSubcategoryPreset(preset: Preset) {
    if (this.updating) {
      return;
    }
    if (!this.specs().selectedPreset || this.specs().selectedPreset?.id !== preset.id) {
      this.updateSpecs("selectedPreset", preset);
      this.updateForm();
    }
  }

  selectPresetMatch(preset: Preset) {
    this.updateSpecs("length", preset.length);
    this.updateSpecs("width", preset.width);
    this.updateSpecs("depth", preset.depth);
    this.updateLists("presetMatches", []);
    this.listPrices();
  }

  addCustomQuantity() {
    if (this.specs().quantity) {
      let valid = true;
      this.quantityError = "";
      const quantity = +this.specs().quantity;

      if (this.specs().luxe) {
        if (quantity === 1) {
          this.quantityError = "Minimum order is " + this.specs().selectedPriceDataBox!.boxType?.luxeMinimumOrder;
          valid = false;
        } else {
          if (quantity < (this.specs().selectedPriceDataBox!.boxType?.luxeMinimumOrder ?? 0)) {
            this.quantityError = "Minimum order is " + this.specs().selectedPriceDataBox!.boxType?.luxeMinimumOrder;
            valid = false;
          }
        }
      } else {
        if (quantity === 1) {
          valid = this.specs().selectedPriceDataBox!.boxType?.subcategory?.allowCustomSample ?? false;
        } else if (quantity < (this.specs().selectedPriceDataBox!.boxType?.minimumOrder ?? 0)) {
          this.quantityError = "Minimum order is " + this.specs().selectedPriceDataBox!.boxType?.minimumOrder;
          valid = false;
        }
      }
      if (quantity > (this.specs().selectedPriceDataBox!.boxType?.maxQuantity ?? 0)) {
        this.quantityError = "Maximum order is " + this.specs().selectedPriceDataBox!.boxType?.maxQuantity;
        valid = false;
      }
      if (valid) {
        if (quantity > 0) {
          this.listPrices();
        }
      }
    }
  }

  showModal(template: TemplateRef<any>, templateClass?: any) {
    if (templateClass) {
      this.modal = this.bsModalService.show(template, { class: templateClass });
    } else {
      this.modal = this.bsModalService.show(template, { class: "modal-lg" });
    }
  }

  hide() {
    this.modal!.hide();
  }

  // If product is tissue, multiply be 100 to get the # of sheets instead of reams
  stripText() {
    this.updateSpecs("enteredCustomQuantity", `${this.specs().selectedPrice?.quantity ?? 0}`);
  }

  createAndDownloadTemplate() {
    this.updateSpecs("quantity", this.specs().selectedPrice?.quantity ?? 0);
    this.downloadingTemplate.set(true);

    this.getTemplate(true)
      .then((template) => {
        this.templateDownloadUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${environment.apiHost}/project/container/${template.container}/${template.templateZip}?download=true&downloadAs=${template.templateZipName}`
        );
        this.downloadingTemplate.set(false);
      })
      .catch(() => {
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
        this.templateLoading.set(false);
      });
  }

  async createBasicContainer() {
    this.stateSvc.updateState({ targetService: "createBasicContainer" });

    if (this.checkUser()) {
      this.templateLoading.set(true);
      this.getTemplate()
        .then((template) => {
          template.userId = (this.authService as FXAuthService).getUser()?.id;
          this.createBasicDesign(template)
            .then((design) => {
              this.goToBuilder(design);
            })
            .catch((error) => {
              this.logger.error(error);
              this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
              this.templateLoading.set(false);
            });
        })
        .catch((error) => {
          this.logger.error(error);

          this.templateLoading.set(false);
          this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    }
  }

  private createBasicDesign(template: any) {
    try {
      const design = {
        customerId: template.userId,
        container: template.containerName,
        type: "BASIC",
        name: "My Box",
        dimensions: template.dimensions.replace(/\s/g, ""),
        boardType: template.boxCode,
        boardStrength: this.specs().selectedBoardStrength?.strength,
        kraftType: this.getPaperTypeID(this.specs().outerMaterial),
        secondaryKraftType: this.getPaperTypeID(this.specs().innerMaterial),
        preset: template.preset,
        filename: new Date().getTime().toString(), // Per ESKO's request, use epoch as filename.
        gloss: this.specs().selectedFinish === "Gloss",
        luxe: this.specs().luxe,
        twoSided: this.specs().twoSided,
        insideOnly: this.specs().insideOnly,
      };

      // tslint:disable-next-line: no-shadowed-variable
      return new Promise((resolve, reject) => {
        this.designService
          .createDesign(template.userId, design)
          .then((savedDesign) => {
            template.basicdesign = savedDesign;
            resolve(template);
          })
          .catch((error) => {
            console.log("Unable to create design", error);
            reject(error);
          });
      });
    } catch (error) {
      this.logger.error(error);
      this.logger.error(JSON.stringify(template, null, 2));
      return Promise.reject(error);
    }
  }

  private async goToBuilder(design: any) {
    try {
      const productDetails: ProductDetailsModel = {
        Category: design.boxType.subcategory.category.code,
        Subcategory: design.boxType.subcategory.code,
        Code: design.boxType.code,
        BoardStrength: design.boardStrength ? design.boardStrength.strength : null,
        ContainerName: design.containerName,
        paperType: design.kraftType,
        id: design.basicdesign?.id ?? 0,
        BoxDimensions: design.dimensions,
        Preset: design.preset,
        gloss: this.specs().selectedFinish === "Gloss",
        luxe: this.specs().luxe,
        twoSided: this.specs().twoSided,
        insideOnly: this.specs().insideOnly,
        print: this.specs().printing ?? "",
        finish: this.specs().selectedFinish ?? "",
        categoryName: design.boxType.description,
        material:  this.specs().outerMaterial,
        outerMaterial: this.specs().outerMaterial,
        innerMaterial: this.specs().innerMaterial,
        continuedProject: false,
        rush: false,
        length: this.specs().length,
        width: this.specs().width,
        depth: this.specs().depth,
        quantity: this.specs().quantity,
      };
      this.stateSvc.updateState({ productDetails: productDetails });
      // TODO: temp until I can find who uses this
      this.stateSvc.updateState({ quantity: productDetails.quantity });
      this.router.navigate(["/design"]);
    } catch (error) {
      this.logger.error(error);
      this.logger.error(JSON.stringify(design, null, 2));
      this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
    }
  }

  async createAdvancedContainer() {
    this.stateSvc.updateState({ targetService: "createAdvancedContainer" });
    if (this.checkUser()) {
      this.templateLoading.set(true);
      this.getTemplate()
        .then((template) => {
          template.userId = (this.authService as FXAuthService).getUser()?.id;
          this.createAdvancedDesign(template)
            .then((design) => {
              this.templateLoading.set(false);
              this.goToUploadDesign(design);
            })
            .catch((error) => {
              this.logger.error(error);
              this.templateLoading.set(false);
              this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
            });
        })
        .catch((error) => {
          this.logger.error(error);
          this.templateLoading.set(false);
          this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
        });
    }
  }

  private createAdvancedDesign(template: any) {
    const design = {
      customerId: template.userId,
      container: template.containerName,
      type: "ADVANCED",
      name: "My Box",
      dimensions: template.dimensions.replace(/\s/g, ""),
      boardType: template.boxCode,
      boardStrength: template.boardStrength.strength,
      kraftType: this.getPaperTypeID(this.specs().outerMaterial),
      secondaryKraftType: this.getPaperTypeID(this.specs().innerMaterial),
      preset: template.preset,
      filename: new Date().getTime().toString(), // Per ESKO's request, use epoch as filename.
      gloss: this.specs().selectedFinish === "Gloss",
      luxe: this.specs().luxe,
      twoSided: this.specs().twoSided,
      insideOnly: this.specs().insideOnly,
    };
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.designService
        .createDesign(template.userId, design)
        .then((savedDesign) => {
          template.design = savedDesign;
          resolve(template);
        })
        .catch((error) => {
          console.log("Unable to create design", error);
          reject(error);
        });
    });
  }

  async goToUploadDesign(design: any) {
    const uploadDetails = {
      // Origin: "designer",
      // Category: this.state().categoryCode,
      Subcategory: design.boxType.aeCode,
      Code: design.boxType.code,
      BoardStrength: design.boardStrength.strength,
      ContainerId: design.containerName,
      kraftType: this.getPaperTypeID(this.specs().outerMaterial),
      secondaryKraftType: this.getPaperTypeID(this.specs().innerMaterial),
      // paperType: this.getPaperTypeID(this.specs().outerMaterial),
      boxDimensions: design.dimensions,
      gloss: this.specs().selectedFinish === "Gloss",
      preset: this.specs().selectedPreset !== undefined,
      luxe: this.specs().luxe,
      options: this.options ?? "",
      twoSided: this.specs().twoSided,
      insideOnly: this.specs().insideOnly,
      quantity: this.specs().selectedPrice?.quantity,
    };

    this.templateLoading.set(false);
    this.stateSvc.updateState({ uploadDetails: uploadDetails });
    this.router.navigate(["upload-design"]);
  }
  addToCart() {
    this.modal?.hide();
    if (
      this.getPaperTypeID(this.specs().outerMaterial) === "pkw" ||
      this.getPaperTypeID(this.specs().innerMaterial) === "pkw"
    ) {
      this.paperTypeId = "pkw";
    } else if (this.state().categoryCode === "Event Boxes") {
      this.paperTypeId = this.getPaperTypeID(this.specs().outerMaterial);
    } else if (
      this.getPaperTypeID(this.specs().outerMaterial) === "kw" &&
      this.getPaperTypeID(this.specs().innerMaterial) === "k"
    ) {
      this.paperTypeId = "kw";
    } else if (
      this.getPaperTypeID(this.specs().outerMaterial) === "k" &&
      this.getPaperTypeID(this.specs().innerMaterial) === "kw"
    ) {
      this.paperTypeId = "kw";
    } else if (
      this.getPaperTypeID(this.specs().outerMaterial) === "k" ||
      this.getPaperTypeID(this.specs().innerMaterial) === "k"
    ) {
      this.paperTypeId = "k";
    } else {
      this.paperTypeId = "kw";
    }
    if (this.state().subcategoryCode.startsWith("SNAP2S")) {
      this.options = "superseal";
    }

    this.templateLoading.set(true);
    this.getTemplate()
      .then((response) => {
        this.boxService
          .getType(this.boxCode!)
          .then((type) => {
            this.checkoutService
              .createNoprint(
                response.containerName,
                this.boxCode,
                this.specs().selectedBoardStrength?.strength,
                this.getPaperTypeID(this.specs().outerMaterial),
                this.getPaperTypeID(this.specs().innerMaterial),
                this.specs().selectedFinish === "Gloss",
                this.specs().luxe,
                undefined,
                this.specs().length,
                this.specs().width,
                this.specs().depth,
                this.specs().selectedPreset,
                this.specs().twoSided,
                this.specs().insideOnly,
                false,
                this.options,
                this.specs().quantity,
                this.specs().printing!.indexOf("No Printing") >= 0
              )
              .then((responses) => {
                responses.quantity = this.specs().selectedPrice?.quantity;
                this.updateSpecs("quantity", responses.quantity);
                const lineItem = this.checkoutService.createLineItem(responses.containerName, type, responses);
                // this.boxService.getType(this.boxCode).then(type => {
                // const lineItem = this.checkoutService.createNoprintLineItem( response, type);
                this.checkoutService
                  .addToCart(lineItem)
                  .then(() => {
                    this.stateSvc.updateState({ targetUrl: "/cart" });
                    this.router.navigate(["/cart"]);
                  })
                  .catch(() => {
                    this.templateLoading.set(false);
                    this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
                  });
              })
              .catch(() => {
                this.templateLoading.set(false);
                this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
              });
          })
          .catch(() => {
            this.templateLoading.set(false);
            this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
          });
      })
      .catch(() => {
        this.templateLoading.set(false);
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });
  }

  async saveProject() {
    let template;
    await this.getTemplate()
      .then((response) => {
        template = response;
      })
      .catch(() => {
        this.templateLoading.set(false);
        this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
      });

    if (this.checkUser()) {
      this.design = {
        container: template!.containerName,
        type: "BASIC",
        outside: "",
        inside: "",
        twoSided: this.specs().twoSided,
        insideOnly: this.specs().insideOnly,
        name: "My Box",
        dimensions: template!.dimensions.replace(/\s/g, ""),
        subcategory: template!.boxType.subcategory,
        boardType: template!.boardType,
        boardStrength: template!.boardStrength.strength,
        kraftType: this.getPaperTypeID(this.specs().outerMaterial),
        secondaryKraftType: this.getPaperTypeID(this.specs().innerMaterial),
        preset: template!.preset,
        gloss: this.specs().selectedFinish === "Gloss",
        luxe: this.specs().luxe,
        quantity: this.specs().quantity,
        optionsArray: [],
        options: "",
        filename: new Date().getTime().toString(), // Per ESKO's request, use epoch as filename.
      };

      this.designService
        .getDesign((this.authService as FXAuthService).getUser()?.id ?? 0, template!.containerName)
        .then((response) => {
          this.design = { ...response, ...this.design };
          this.designService
            .updateDesign(this.user!.id!, this.design)
            .then(() => {
              this.router.navigate(["/continue-project"]);
            })
            .catch(() => {
              this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
            });
        })
        .catch((error) => {
          if (error.status === 404) {
            this.designService
              .createDesign((this.authService as FXAuthService).getUser()?.id ?? 0, this.design)
              .then((_response) => {
                this.router.navigate(["/continue-project"]);
              })
              .catch(() => {
                this.bsModalService.show(ConfirmationModalComponent).content?.showModal(ERROR_BODY, ERROR_TITLE);
              });
          }
        });
    }
  }

  private getTemplate(downloadOnly = false): Promise<any> {
    const dimensions = this.specs().length + " x " + this.specs().width + " x " + this.specs().depth;

    const template: any = {
      boardType: this.specs().selectedBoardType ?? "",
      boxCode: this.boxCode ?? "",
      kraftType: this.getPaperTypeID(this.specs().material),
      boardStrength: this.specs().selectedBoardStrength,
      boardStrengthName: this.specs().selectedBoardStrength
        ? this.specs().selectedBoardStrength?.strength ?? "" + this.specs().selectedBoardType?.flute ?? ""
        : null,
      flute: this.specs().selectedBoardType?.flute ?? "",
      boxLength: this.specs().length,
      boxWidth: this.specs().width,
      boxDepth: this.specs().depth,
      dimensions,
      preset: this.specs().selectedPreset !== undefined,
      boxName:
        this.state().subcategoryCode.toLowerCase() === "dhc"
          ? this.specs().selectedPriceDataBox?.boxType?.name
          : this.specs().selectedPreset?.type?.name,
      boxType: undefined,
      boardTypeName: undefined,
      templateName: undefined,
      containerName: undefined,
    };

    return new Promise((resolve, reject) => {
      this.boxService
        .getType(template.boxCode)
        .then((boxType) => {
          template.boxType = boxType;

          if (template.boxCode && template.boxCode.startsWith("SNAP2")) {
            template.boxType.aeCode = template.boxType.code;
            if (this.specs().selectedVariant) {
              if (this.specs().selectedVariant?.name === "Super Seal" && this.specs().length === 6) {
                template.boxType.aeCode =
                  template.boxType.code.slice(0, 5) + "S662" + template.boxType.code.slice(5, 6);
              } else if (this.specs().selectedVariant?.name === "Super Seal" && this.specs().length === 9) {
                template.boxType.aeCode =
                  template.boxType.code.slice(0, 5) + "S963" + template.boxType.code.slice(5, 6);
              } else if (this.specs().selectedVariant?.name === "Super Seal" && this.specs().length === 12) {
                template.boxType.aeCode =
                  template.boxType.code.slice(0, 5) + "S1294" + template.boxType.code.slice(5, 6);
              } else if (
                this.specs().selectedVariant?.name === "Returnable Super Seal" &&
                this.specs().length === 9 &&
                this.specs().depth === 3.5
              ) {
                template.boxType.aeCode =
                  template.boxType.code.slice(0, 5) + "S9635" + template.boxType.code.slice(5, 6);
              } else if (
                this.specs().selectedVariant?.name === "Returnable Super Seal" &&
                this.specs().length === 6 &&
                this.specs().depth === 3.5
              ) {
                template.boxType.aeCode =
                  template.boxType.code.slice(0, 5) + "S6635" + template.boxType.code.slice(5, 6);
              } else if (this.specs().selectedVariant?.name === "Returnable Super Seal" && this.specs().length === 12) {
                template.boxType.aeCode =
                  template.boxType.code.slice(0, 5) + "S1294" + template.boxType.code.slice(5, 6);
              }
            }
          }

          const dimensionsArr = template.dimensions.replace(/&quot;/g, "").split("x");
          const lengthName = dimensionsArr[0].replace(/ /g, "").replace(".", "_") + "_";
          const widthName = dimensionsArr[1].replace(/ /g, "").replace(".", "_") + "_";
          const depthName = dimensionsArr[2].replace(/ /g, "").replace(".", "_");
          template.boardTypeName =
            boxType.boardTypeCode + (template.boardStrength ? "-" + template.boardStrength.code : "");
          template.flute = boxType.boardType?.code;
          template.templateName =
            boxType.aeCode +
            "-" +
            template.boardTypeName +
            "-" +
            lengthName.trim() +
            widthName.trim() +
            depthName.trim();

          this.containerService
            .getTemplate(template)
            .then((response) => {
              template.containerName = response.container;
              template.templateName = response.template;
              resolve(downloadOnly ? response : template);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((reason) => reject(reason));
    });
  }

  private checkUser(): boolean {
    this.stateSvc.updateState({ optionSpecs: this.specs() });
    if (!(this.authService as FXAuthService).getUser()?.id) {
      this.showModal(this.connectedModal, "connectionModal");
      return false;
    } else {
      return true;
    }
  }

  updateSpecs(target: string, value: any) {
    this.specs.update((s: any) => {
      s[target] = value;
      return s;
    });
  }

  updateLists(target: string, value: any) {
    this.lists[target].update((s: any) => {
      return value ?? [];
    });
  }
  compareIdFn(o1: any, o2: any): boolean {
    return o1.id === o2?.id;
  }
  comparePriceFn(o1: any, o2: any): boolean {
    return o1.totalPrice === o2.totalPrice;
  }
}
