<div class="navbar-nav">
    <div class="progress-bar-table" [class.inactive]="step0">
        <div class="step step1" [class.active]="step1">
            <a href="javascript:" (click)="gotoStart()">
            <div class="label">Start</div>
            <div class="circle-container">
                <div  class="outer-circle">
                    <div class="inner-circle"></div>
                </div>
            </div>
            </a>
            <div class="line-right"></div>
        </div>
        <div class="step step2" [class.active]="step2">
            <div class="label">Style</div>
            <div class="circle-container">
                <div (click)="goToBoxStyles()" class="outer-circle">
                    <div class="inner-circle"></div>
                </div>
            </div>
            <div class="line"></div>
        </div>
        <div class="step step3" [class.active]="step3">
            <div class="label">Size & Specs</div>
            <div class="circle-container">
                <div (click)="goToBoxOptions()" class="outer-circle">
                    <div class="inner-circle"></div>
                </div>
            </div>
            <div class="line"></div>
        </div>
        <div class="step step4" [class.active]="step4">
            <div class="label">Design</div>
            <div class="circle-container">
                <div (click)="goToDesigner()" class="outer-circle">
                    <div class="inner-circle"></div>
                </div>
            </div>
            <div class="line"></div>
        </div>
        <div class="step step5" [class.active]="step5">
            <div class="label">Review</div>
            <div class="circle-container">
                <div class="outer-circle">
                    <div class="inner-circle"></div>
                </div>
            </div>
            <div class="line"></div>
        </div>
        <div class="step step6" [class.active]="step6">
            <div class="label">Checkout</div>
            <div class="circle-container">
                <div routerLink="/cart" class="outer-circle">
                    <div class="inner-circle"></div>
                </div>
            </div>
            <div class="line-left"></div>
        </div>
    </div>
</div>
  <form name="back" #back ngNoForm [action]="state().referer" method="post">
    <input name="action" type="hidden" value="cancel" />
  </form>