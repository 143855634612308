import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from "@angular/common/http";
import { ClientSessionStateService } from "./services/sessionState.service";
import { inject } from "@angular/core";
import { startWith } from 'rxjs';

export const sessionInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
    const stateSvc = inject(ClientSessionStateService);
    let newReq = req;
    const sessionKey = stateSvc.getStateValue("sessionKey")
    if(sessionKey) {
      const headers = req.headers.set('x-session', sessionKey);
      newReq = req.clone({ headers});

      const referer = stateSvc.getStateValue('referer');
      if (!referer || referer.startsWith('undefined')) {
        let url = stateSvc.getStateValue('authSessionData').cartUrl;
        if(!url) url = stateSvc.getStateValue("authSessionData").loginUrl;
        if(url)
        stateSvc.updateState({ referer: `${url}?action=cancel` });
      }
    }
  return next(newReq);
};
