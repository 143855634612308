import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Signal,
  ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ClientSessionStateService } from "../../services/sessionState.service";
import { ClientSessionStateModel } from "../../models/sessionState.model";
import { FXAuthService } from "../../services/fx-auth.service";
import { ENVIRONMENT } from "../../../../../libs/models/src/lib/injection.tokens";

@Component({
  selector: "app-connected-better",
  standalone: true,
  imports: [FormsModule, MatFormFieldModule],
  templateUrl: "./connected-better.component.html",
  styleUrl: "./connected-better.component.scss",
})
export class ConnectedBetterComponent implements OnInit {
  @ViewChild("auth", { static: false }) authFormElement!: ElementRef;
  loginUrl: SafeUrl = "";
  productConfigData = "";
  configurationData = "";
  formKey = "";
  supportEmail = 'mailto:' + this.env.config.supportEmail;
  supportPhone = this.env.config.supportPhone;

  state!: Signal<ClientSessionStateModel>;
  constructor(
    private sanitized: DomSanitizer,
    private stateSvc: ClientSessionStateService,
    private authSvc: FXAuthService,
    @Inject(ENVIRONMENT) private env: any
  ) {}

  async ngOnInit(): Promise<void> {
    this.state = this.stateSvc.getState();
    let authSessionData = this.state().authSessionData;
    if (!authSessionData?.loginUrl) {
      await this.authSvc.getAuthData().then((res: any) => {
        res.userId = undefined;
        this.stateSvc.updateState({ authSessionData: res });
        authSessionData = res;
      });
    }

    this.loginUrl = this.sanitized.bypassSecurityTrustResourceUrl(authSessionData.loginUrl);
    this.productConfigData = authSessionData.productConfigData || '{\"dummy\": \"value\""}';
    this.configurationData = `{\"session\":\"${authSessionData.sessionKey}\"}`;
    this.formKey = authSessionData.formKey;
  }
}
